import React from "react"
import Layout from "../components/layout"
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  InputGroup,
  FormControl,
} from "react-bootstrap"
import phone from "../images/phone.svg"
import mail from "../images/mail.svg"
import padlockOpen from "../images/padlock-open.svg"
import { translate } from "../utils"

const SignUpTwo = () => {
  return (
    <Layout>
      <div className="formWrapper">
        <Container>
          <Row className="justify-content-center">
            <Col lg="7">
              <div className="text-left">
                <h3 className="h1-title mb-2 gray-secondary">
                  Complétez
                  <span className="d-flex">{translate("sign Up")}</span>
                </h3>
                <p className="pb-4">
                  {translate('des informations essentielles pour compléter votre profil')}.
                </p>
                <Form>
                  <Row>
                    <Col md="6">
                      <Form.Label className="d-block d-lg-none">
                        GSM*
                      </Form.Label>
                      <InputGroup className="mb-3">
                        <InputGroup.Prepend>
                          <InputGroup.Text id="basic-addon1">
                            <img
                              width={12}
                              height={20}
                              className="img-fluid"
                              src={phone}
                              alt="phone"
                            />
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                          placeholder="GSM*"
                          aria-label=""
                          aria-describedby="basic-addon1"
                        />
                      </InputGroup>
                    </Col>
                    <Col md="6">
                      <Form.Label className="d-block d-lg-none">
                        {} Adresse mail
                      </Form.Label>
                      <InputGroup className="mb-3">
                        <InputGroup.Prepend>
                          <InputGroup.Text id="basic-addon1">
                            <img
                              width={16}
                              height={8}
                              className="img-fluid"
                              src={mail}
                              alt="mail"
                            />
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                          placeholder={translate('Adresse mail')+"*"}
                          aria-label=""
                          aria-describedby="basic-addon1"
                        />
                      </InputGroup>
                    </Col>
                    <Col md="6">
                      <Form.Label className="d-block d-lg-none">
                        Choose password
                      </Form.Label>
                      <InputGroup className="mb-3">
                        <InputGroup.Prepend>
                          <InputGroup.Text id="basic-addon1">
                            <img
                              width={11}
                              height={17}
                              className="img-fluid"
                              src={padlockOpen}
                              alt="mail"
                            />
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                          placeholder={translate("password")}
                          aria-label=""
                          aria-describedby="basic-addon1"
                        />
                      </InputGroup>
                    </Col>
                    <Col md="6">
                      <Form.Label className="d-block d-lg-none">
                        {translate('confirm password')}
                      </Form.Label>
                      <InputGroup className="mb-3">
                        <InputGroup.Prepend>
                          <InputGroup.Text id="basic-addon1">
                            <img
                              width={11}
                              height={17}
                              className="img-fluid"
                              src={padlockOpen}
                              alt="mail"
                            />
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                          placeholder="Password"
                          aria-label=""
                          aria-describedby="basic-addon1"
                        />
                      </InputGroup>
                    </Col>
                  </Row>
                  <div className="pt-4 pb-3 text-center">
                    <small className="text-secondary">
                      {translate('un e-mail vient de vous être envoyé, merci de le confirmer avant de poursuivre votre inscription')}.
                    </small>
                  </div>
                  <Button variant="success" type="submit" className="button-yellow" disabled>
                    {translate('sign in')}
                  </Button>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  )
}

export default SignUpTwo
